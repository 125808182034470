import React from "react"
import Layout from "../layouts/es"
import { HelpCircle } from "react-feather"
import SEO from "../components/seo"

const FaqPage = (props) => {
    return <Layout location={props.location}>
        <div>
            <SEO title="Preguntas frecuentes sobre la firma electrónica"
                 description="Preguntas frecuentes sobre la firma electrónica. Que es la firma electrónica. Beneficios de la firma electrónica." />

            <section className="py-5 header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="position-absolute top-right text-primary-dark mt-n12 decor2">
                                <img src={"/img/ic_packages_1.svg"} alt="" />
                            </div>
                            <h3 align="center" className="spacing">Preguntas frecuentes</h3>
                        </div>
                    </div>
                </div>
            </section>

            <section className="f1">
                <div className="container">
                    <br /><br /><br />
                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header-1" id="headingOne">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5>¿Qué es la firma electrónica?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    La firma electrónica es una manera sencilla y legal de obtener el consentimiento o aprobación de documentos o formularios electrónicos. La firma
                                    electrónica es legal y se reconoce como legalmente vinculante en la mayoría de países del mundo, como EEUU o los países de la UE, por
                                    ejemplo.<br /><br />
                                    Tecalis Signature garantiza que los documentos se firman, se gestionan y se entregan a las partes contratantes de un modo seguro.
                                    Tecalis Signature genera también un documento probatorio donde queda registrado el email (tanto del receptor como del remitente), el nombre del
                                    documento y el lugar y el momento exacto en que fue firmado. Además, dicho documento probatorio incluye un sello de tiempo generado por una
                                    Autoridad de Sellado de Tiempo (TSA – Time Stamp Authority) que actúa como tercera parte de confianza, dando total integridad a la
                                    documentación. La generación de todas estas evidencias electrónicas aporta validez legal al proceso.<br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingTwo">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                            aria-controls="collapseTwo">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5>¿Qué es la firma digital?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    La firma digital es una aplicación específica de la firma electrónica. Una firma digital requiere tener una certificación digital. Los
                                    certificados digitales son el componente clave de la tecnología PKI (Public Key Infrastructure) y son emitidos por una autoridad de
                                    certificación para ser utilizados en el proceso de firma digital, que proporciona un alto nivel de autenticación, integridad y seguridad para
                                    todas las partes. Debido a la infraestructura necesaria para manejar la tecnología PKI, las firmas digitales se utilizan habitualmente en
                                    organismos gubernamentales, como ministerios y administraciones públicas, y en industrias reguladas con flujos de trabajo internos, como las
                                    farmacéuticas.<br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5>¿Cuál es la normativa de la firma electrónica por la que se rige Tecalis Signature?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    El proceso de firma electrónica de Tecalis Signature se rige por las normativas de la Unión Europea (Directiva 1999/93/CE Europea), de Estados
                                    Unidos (ESIGN, 2000 y UETA, 1999), del Reino Unido (UK Electronic Communication Act) y del resto de países miembros de la OMC (Organización
                                    Mundial del Comercio), otorgando la validez necesaria para que sea legalmente vinculante.<br /><br />
                                </div>
                            </div>
                        </div>


                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5>¿Cómo funciona Tecalis Signature?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseFour" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    Tecalis Signature está diseñado para que se pueda leer y firmar electrónicamente documentos desde cualquier dispositivo y en cuestión de
                                    minutos, de forma fácil e intuitiva.<br /><br />
                                    Introduce las direcciones de correo electrónico de los firmantes en el orden en que quieres que firmen. Los destinatarios solo tienen que hacer
                                    clic en un vínculo y firmar en un navegador con su ordenador o dispositivo móvil.
                                    Nuestro sistema de notificaciones avisará en cuanto se haya visualizado o firmado el documento, además de generar alertas automáticas cuando se
                                    ha establecido una fecha límite.
                                    Todas las partes involucradas reciben una copia segura del documento firmado. Este se almacena junto con el registro de auditoría en Internet o
                                    en el lugar que prefieras.<br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5>¿Qué es un usuario?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseFive" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    Un usuario es la persona registrada en Tecalis Signature que envía documentos a firmar a través del panel de gestión. Los usuarios pueden
                                    acceder a su cuenta a través de la dirección de correo electrónico y contraseña que han dado de alta previamente en el sistema o mediante su
                                    cuenta de Google.<br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5>¿Cómo puedo controlar el proceso de firma?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseSix" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    Los usuarios de Tecalis Signature disponen de un panel de gestión desde donde pueden consultar y controlar todo el proceso de firma. Desde dicho
                                    panel se puede consultar el estado en el que se encuentra cualquier documento que requiera ser firmado (enviado, pendiente de firma o firmado),
                                    el histórico de los documentos firmados, el archivo documental y el registro de documentos probatorios generados en cada operación
                                    Además, el panel también dispone de un sistema de alertas automatizado para estar informado en todo momento del estado en que se encuentran las
                                    solicitudes de firma.<br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5> ¿Tienen que estar registrados los firmantes?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseSeven" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    No, solo la persona que envía documentos a firmar tiene que estar registrada en el
                                    servicio de Tecalis Signature. Las personas que reciben las peticiones de firma sólo tienen que acceder al documento y firmarlo vía url enviado
                                    a su correo electrónico.<br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5> ¿Cuántos documentos puedo enviar como usuario?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseEight" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    El número de documentos a enviar por usuario depende del plan contratado. Cada plan tiene unas características, funcionalidades y número de
                                    documentos determinados para firmar. Consulta nuestros paquetes para obtener más información.<br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5> ¿Qué evidencias electrónicas contiene el documento probatorio?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseTen" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing check-list">
                                    Nuestro documento probatorio está diseñado para facilitar la lectura y la localización de las diferentes evidencias electrónicas que recogemos
                                    durante el proceso de firma. En el apartado “Información del envío” se detallan las siguientes evidencias electrónicas:<br /><br />

                                    <ul className="spacing mb-5">
                                        <li>Nombre y correo electrónico del emisor.</li>
                                        <li>Nombre y correo electrónico del firmante.</li>
                                        <li>Nombre del documento enviado a firmar.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5> ¿Cuáles son los beneficios de la firma electrónica?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseEleven" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing check-list">
                                    <ul className="spacing mb-5">
                                        <li><b>Reducción de costes:</b> se estima que cada firma en papel le cuesta a las empresas más de 5 euros en gastos asociados, tales como:
                                            papel, imprimir, firmar, escanear, archivar y sustituir los documentos perdidos.
                                        </li>
                                        <li><b>Ahorro en tiempo:</b> los procesos se demoran días para obtener los documentos firmados, mientras que con la firma electrónica es
                                            cuestión de minutos.
                                        </li>
                                        <li><b>Mayor control del proceso:</b> a través del panel de gestión de Tecalis Signature puedes consultar y controlar todo el proceso de
                                            firma: el estado en que se encuentra el documento (enviado, pendiente de firma o firmado), el histórico de documentos firmados, el
                                            archivo documental y el registro de documentos probatorios.
                                        </li>
                                        <li><b>Mejora el nivel de tu servicio:</b> el grado de satisfacción de tus clientes mejorará considerablemente gracias al hecho de no tener
                                            que instalar ninguna aplicación adicional y a la sencillez de uso que ofrece Tecalis Signature.
                                        </li>
                                        <li><b>Ahorro de espacio:</b> la firma electrónica hace innecesaria la existencia de un archivo físico de documentos.</li>
                                        <li><b>Ayuda al medioambiente:</b> la firma electrónica hace posible prescindir del uso de papel. 100 firmas en papel equivalen a: 1081.49
                                            litros de agua, 45.36 kg de madera, 10.89 kg de desechos.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5> ¿Qué me aporta el documento probatorio?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseTwelve" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    El sellado de tiempo es un mecanismo online que permite garantizar que una serie de datos han existido y no han sido alterados desde un instante
                                    específico en el tiempo. Certifica la identidad de la persona que firma y también la fecha y la hora exactas en la que se realiza la firma. Para
                                    garantizar este hecho es necesario utilizar una Autoridad de Sellado de tiempo (TSA – Time Stamp Authority), que actúa como tercera parte de
                                    confianza, declarando la existencia de dichos datos electrónicos en una fecha y hora concretos. El sellado de tiempo utilizado por Tecalis
                                    Signature está reconocido a nivel internacional, por lo que se puede utilizar en cualquier país o región.
                                    <br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve1" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5> ¿Es necesario instalar alguna aplicación?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseTwelve1" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    No es necesario instalar aplicaciones adicionales específicas para enviar ni para firmar documentos. La solicitud de firma se realiza a través
                                    de tu gestor de correo habitual, sin tener que entrar en plataformas o aplicaciones de terceros, y la persona que firma lo puede hacer desde su
                                    dispositivo móvil sin tener que instalar ninguna aplicación extra ya que Tecalis Signature utiliza tecnología cloud, donde podrás acceder a la
                                    misma desde cualquier dispositivo y lugar, con solo disponer de internet y un buscador.
                                    <br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve2" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5>¿Qué sistemas operativos son compatibles?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseTwelve2" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    Los documentos PDF podrán ser firmados a través de un smartphone o tableta con sistemas operativos Windows Mobile (Microsoft), iOS (Apple) y
                                    Android. No es necesario instalar aplicaciones adicionales específicas para enviar ni para firmar documentos. La solicitud de firma se realiza a
                                    través de tu gestor de correo habitual, sin tener que entrar en plataformas o aplicaciones de terceros, y la persona que firma lo puede hacer desde
                                    su dispositivo móvil sin tener que instalar ninguna aplicación extra.<br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve3" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5>¿Tecalis Signature me garantiza seguridad y privacidad?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseTwelve3" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    Tecalis Signature proporciona los más altos niveles de seguridad y confidencialidad. El acceso a los datos se realiza a través de un canal seguro
                                    HTTPS que utiliza un cifrado AES de 256 bits y SSL 256 Bit Encryptionun.<br /><br />
                                    Tecalis Signature genera un documento probatorio con sello de tiempo, emitido por una autoridad certificadora, que asegura que los documentos y
                                    las firmas no han sido alterados. Queda registrado el email (tanto del receptor como del remitente), el nombre del documento y el lugar y el
                                    momento exacto en que fue firmado. Estos documentos tienen plena validez legal ante un tribunal de justicia, amparándose en el derecho probatorio.
                                    Tecalis Signature y todos los documentos se hospedan en instalaciones de tipo II SAS70, que ha obtenido la certificación ISO 27001 e ISO 9001.
                                    <br /><br />
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header-1" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve4" aria-expanded="false"
                                            aria-controls="collapseThree">
                                        <div className="resumen d-flex justify-content-between align-items-center mt-2">
                                            <h5>¿Puedo cambiar mi plan contratado?</h5>
                                            <small className="text-muted">
                                                <HelpCircle className="icon2" />
                                            </small>
                                        </div>
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseTwelve4" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body-1 spacing">
                                    No. Podrás darte de baja cuando lo desees desde “Mi cuenta” o poniéndote en contacto con nosotros. Se dará de baja en el plan contratado en el
                                    siguiente ciclo de facturación mensuales.
                                    <br /><br />
                                </div>
                            </div>
                        </div>
                    </div>

                    <br /><br /><br />
                </div>
            </section>
        </div>
    </Layout>
}

export default FaqPage
